<script setup lang="ts">
import { onClickOutside, useElementBounding } from '@vueuse/core';
import type { IconName } from '../Icon.vue';

export type TableAction = {
  key: string | number;
  label: string;
  className?: string;
  icon?: IconName;
  iconClassName?: string;
  iconStokeWidth?: number;
  disabled?: boolean;
};

// Props and emits
const emits = defineEmits(['clickItem']);
defineProps<{
  items: TableAction[];
}>();

// Data
const open = ref(false);
const container = ref(null);
onClickOutside(container, () => (open.value = false));

const button = ref<HTMLElement | null>(null);
const { left, width, height, top, y, update } = useElementBounding(button);
const elements = ref<HTMLElement | null>(null);
const { width: elementsWidth, height: elementsHeight, update: reload } = useElementBounding(elements);

// Methods
const clickItem = (item: TableAction) => {
  if (item.disabled) return;
  emits('clickItem', item.key);
  open.value = false;
};

const openMenu = async () => {
  await update();
  await reload();
  open.value = !open.value;
};

// Computed
const getWindowHeight = computed(() => {
  return window.innerHeight;
});

const getYPosition = computed(() => {
  if (getWindowHeight.value - y.value > elementsHeight.value + 32) {
    return `top: ${top.value + height.value}px;`;
  } else {
    return `top: ${y.value - elementsHeight.value - 16}px;`;
  }
});
</script>
<template>
  <div ref="container" class="relative inline-block text-left">
    <div class="group">
      <div
        v-if="!$slots.button"
        ref="button"
        class="flex z-0 cursor-pointer items-center bg-white hover:bg-gray-50 justify-between border rounded-md px-3 text-sm font-medium group-hover:text-gray-700 text-gray-600"
        @click="openMenu()"
      >
        <ui-icon class="fill-black w-4" name="EllipsisVertical" />
      </div>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-y-95"
      enter-to-class="transform opacity-100 scale-y-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-y-100"
      leave-to-class="transform opacity-0 scale-y-95"
    >
      <div
        v-if="open"
        ref="elements"
        :style="`left: ${left - elementsWidth + width}px;` + getYPosition"
        class="fixed max-h-[150px] origin-top z-[9999] bg-white overflow-y-auto mt-2 px-1 divide-y divide-gray-100 rounded-[8px] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div class="py-1">
          <div v-for="item in items" :key="item.key" :disabled="item.disabled" class="rounded-[4px]" @click="clickItem(item)">
            <slot name="item" :item="item">
              <div
                class="select-none cursor-pointer flex items-center rounded-md text-gray-700 hover:bg-gray-50"
                :class="[item.disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100', 'block px-3 py-1.5 text-sm']"
              >
                <div>
                  <ui-icon
                    v-if="item.icon"
                    :class="item.iconClassName"
                    :name="item.icon"
                    :stroke-width="item.iconStokeWidth"
                    class="w-4 mr-2"
                  />
                </div>

                <p class="whitespace-nowrap text-sm ml-2" :class="item.className">{{ item.label }}</p>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
